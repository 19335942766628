import React, { Suspense, useEffect } from "react";
import { BrowserRouter } from "react-router-dom";
import KnsApp from "pages";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import AOS from 'aos';
import 'aos/dist/aos.css';
// import './App.css'

function App() {

  useEffect(() => {
    AOS.init({
      duration : 2000,
      startEvent: 'DOMContentLoaded',
      animatedClassName: 'aos-animate',
      easing: 'ease',
    });
    AOS.refreshHard();
  }, [])

  return (
    <BrowserRouter>
      <Suspense fallback={""}>
        <KnsApp />
      </Suspense>
    </BrowserRouter>
  );
}

export default App;
