import React, { Component } from "react";

const AppDesktop = React.lazy(() => import("../index.desktop"));
const AppMobile = React.lazy(() => import("../index.mobile"));

class KnsApp extends Component {
  constructor(props){
    super(props)
    this.state = {
      isLoading: false,
      isMobile: false
    };
  }

  resize() {
    this.setState({isMobile: window.innerWidth <= 760});
  }

  componentDidMount() {
    window.addEventListener("resize", this.resize.bind(this));
    this.resize();
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.resize.bind(this));
  }

  render() {
    if (this.state.isLoading) {
      return <div></div>;
    }
    let KnsScreen = !this.state.isMobile ? <AppDesktop /> : <AppMobile />;

    return  KnsScreen
  }
}

export default KnsApp;